import * as React from 'react'
import { dedupeCategories } from '../utils/dedupeCategories'
import { useStaticQuery, graphql } from 'gatsby'
import { activeItem, filter, filterItem } from '../styles/filter.module.css'

export default function Categories(props: any) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "project"} } }) {
        edges {
          node {
            id
            frontmatter {
              category
            }
          }
        }
      }
    }
  `)
  const dedupedCategories = dedupeCategories(data.allMarkdownRemark)
  const categories = dedupedCategories.map((category, index) => 
    <li className={`${filterItem} ${props.selectedCategory == category ? activeItem : ''}`} onClick={()=> props.showCategory(category)} key={index}>
      {category}
    </li>
  )

  return (
    <div>
      <ul className={filter}>
        <li className={`${filterItem} ${props.selectedCategory == '' ? activeItem : ''}`} onClick={()=> props.showCategory('')}>All</li>
        {categories}
      </ul>
    </div>
  )
} 