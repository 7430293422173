import * as React from "react"
import { useState } from 'react'
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import { Card } from "../components/card/Card"
import { Image } from "../components/image/Image"
import { header, container, h1, grid, card, title, image } from '../styles/projects.module.css'
import Filter from '../templates/filter'

export default function Home({ data }) {

  const [category, setCategory] = useState('');

  const showSelectedCategory = (category: any) => {
    setCategory(category)
  }

  const filtered = data.allMarkdownRemark.edges.filter((post)=> post.node.frontmatter.category.includes(category))

  return (
    <Layout>
      <header className={header}>
        <div className={container}>
          <h1 className={h1}>Projects</h1>
        </div>
      </header>
      <div className={container}>
        <Filter 
          showCategory={showSelectedCategory} 
          selectedCategory={category} 
        />
      </div>
      <div className={container}>
        <div className={grid}>
          {(category ? filtered : data.allMarkdownRemark.edges).map(({ node }, index: number) => (
            <Link className={card} to={node.fields.slug} key={index}>
              <Image className={image} url={node.frontmatter?.thumbnail?.publicURL} />
              <Card key={node.id}>
                <h3 className={title}>{node.frontmatter.title}{" "}</h3>
                <p>{node.excerpt}</p>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { type: { eq: "project"}  } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            category
            title
            date(formatString: "DD MMMM, YYYY")
            thumbnail {
              publicURL
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`