import React from 'react';
import * as styles from './Image.module.css'

export interface ImageProps {
  url: string;
  className?: string,
  props: any;
}

export const Image: React.FC<ImageProps> = ({
  url,
  className,
  ...props
}) => {
  return (
    <img className={`${styles.imgWrapper} ${className}`} src={url} {...props} />
  )
}